@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz,wght@6..96,500;6..96,600&family=Inter:wght@300;500&display=swap");

/* Reset css */

* {
  padding: 0;
  margin: 0;
  border: none;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a,
a:link,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
aside,
footer,
main,
nav,
promo,
section {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}
ul,
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
img,
svg {
  max-width: 100%;
  height: auto;
}
address {
  font-style: normal;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}
input::-ms-clear {
  display: none;
}
button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  background: 0 0;
  cursor: pointer;
}
button:active,
button:focus,
input:active,
input:focus {
  outline: 0;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
label {
  cursor: pointer;
}
legend {
  display: block;
}

/* Style */

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  background-color: #fff;
  user-select: none;
  text-align: center;
}

.container {
  padding: 0 16px;
}
