.footer {
    padding: 30px 0 20px;
}

.footer__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  list-style-type: none;
  margin-bottom: 30px;
}

.footer__socials a {
    display: flex;
    width: 40px;height: 40px;
}