.header {
  padding: 12px 16px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
}

.shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Bodoni Moda", serif;
  letter-spacing: -0.15rem;
}

.logo-elle {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 0.82;
}

.logo-store {
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 0.82;
}

.button {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.button div {
  width: 14px;
  height: 1px;
  background-color: #000;
}
