.promo {
  margin-top: 115px;
  background: url("../../img/coat.webp") center center/cover no-repeat;
  height: calc(100svh - 130px);
  padding-top: 30%;
}

.title {
  font-size: 3.5rem;
  font-style: italic;
  color: #fff;
}
