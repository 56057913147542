.shop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #000;
  padding: 4px 8px;
}

.shop img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
