.shops {
  margin-top: 40px;
}

.shops h2 {
  margin-bottom: 30px;
}

.shops__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  list-style-type: none;
}

.shops__list li {
  width: 393px;
}

@media (max-width: 425px) {
  .shops__list li {
    width: 100%;
  }
}
